import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Components
import Seo from "../../components/Seo"
import Features from "../../components/Features"
import Intro from "../../components/Intro"
import LogoBanner from "../../components/LogoBanner"
import TeamRituals from "../../components/TeamRituals"

// Constants
import { paths } from "../../constants/paths"

// Styles
import mq from "../../styles/breakpoints"
import colors from "../../styles/colors"

// Types
import { IFeaturesPage } from "../../prismic-types/features-page"

// Custom Hooks
import { useLocale } from "../../hooks/useLocale"

interface IProps {
  content: IFeaturesPage
}

const FeaturesPage: React.FC<IProps> = ({ content }) => {
  const { getLocalizedLink } = useLocale()
  const slug = "platform"

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug={slug}
        imageUrl={content.page.image.imageUrl}
        breadCrumbItems={[{ name: "Platform", url: slug }]}
      />
      <Intro
        title={content.page.title}
        text={content.page.text}
        desktopImg={content.page.image}
        buttonLabel={content.page.buttonLabel}
        buttonLink={getLocalizedLink(paths.demo)}
        buttonVariant="primary"
        variant="page"
        color="purple"
        shapeColor={colors.main["800"]}
      />
      {content.page.teamRituals && (
        <TeamRituals teamRituals={content.page.teamRituals} />
      )}
      {content.page.features.length > 0 && (
        <Features features={content.page.features} />
      )}
      {content.page.logos && <StyledLogoBanner {...content.page.logos} />}
      <TextImageBlock
        title={content.page.ctaTitle}
        text={content.page.ctaText}
        desktopImg={content.page.ctaImage}
        buttonLabel={content.page.ctaButtonLabel}
        buttonVariant="tertiary"
        buttonLink={getLocalizedLink(paths.demo)}
        variant="detail"
        textColor="light"
        titleTag="h2"
        shapeColor=""
      />
    </>
  )
}

const TextImageBlock = styled(Intro)`
  margin-bottom: 0;
  background: linear-gradient(
    180.06deg,
    ${colors.purpleLight} 22.37%,
    ${colors.purpleDarkest} 99.95%
  ) !important;

  ${mq.from.M`
    padding-bottom: 160px;
  `}
`

const StyledLogoBanner = styled(LogoBanner)`
  background: ${colors.grayLight};
`

export const query = graphql`
  query featuresPage($locale: String!) {
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    allPrismicFeaturesPage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          last_publication_date
          first_publication_date
          lang
          data {
            # Meta
            meta_title
            meta_description

            # Intro
            title {
              text
            }
            intro {
              html
            }
            intro_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            intro_button_label

            # CTA
            cta_title {
              text
            }
            cta_text {
              html
            }
            cta_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            cta_button_label

            logos_label

            # Shape block
            shape_block_title {
              text
            }
            shape_block_text {
              html
            }
            shape_block_label
            shape_block_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            shape_block_prices {
              price
              label
              caption
              button_link
              button_label
            }

            # Body (features)
            body {
              ... on PrismicFeaturesPageBodyFeature {
                id
                slice_label
                slice_type
                primary {
                  feature_color
                  feature_label
                  feature_button
                  feature_description {
                    html
                  }
                  feature_image {
                    alt
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                  feature_title {
                    text
                  }
                }
                items {
                  subject_description {
                    html
                  }
                  subject_title
                }
              }
              ... on PrismicFeaturesPageBodyTeamRitualCarousel {
                id
                slice_label
                slice_type
                primary {
                  description {
                    html
                    text
                  }
                  title1 {
                    html
                    text
                  }
                }
                items {
                  image {
                    alt
                    url
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    last_publication_date,
    first_publication_date,
    lang,
  } = data.allPrismicFeaturesPage.edges[0].node
  const logoData =
    data.allPrismicEvaluationDetail.edges[0].node.data.testimonials

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const features = raw.body
    .filter((slice: any) => slice.slice_type === "feature")
    .map((feature: any) => ({
      label: feature.primary.feature_label,
      color: feature.primary.feature_color,
      title: feature.primary.feature_title.text,
      text: feature.primary.feature_description.html,
      buttonLabel: feature.primary.feature_button,
      image: {
        alt: feature.primary.feature_image.alt,
        url: feature.primary.feature_image.fluid,
      },
      subjects: feature.items.map((item: any) => ({
        title: item.subject_title,
        text: item.subject_description.html,
      })),
    }))

  const ritualCarousel = raw.body
    .filter((slice: any) => slice.slice_type === "team_ritual_carousel")
    .map((carousel: any) => ({
      title: carousel.primary.title1.html,
      description: carousel.primary.description.html,
      images: carousel.items.map((item: any) => ({
        url: item.image.fluid,
        alt: item.image.alt,
      })),
    }))

  const logos = {
    label: raw.logos_label,
    logos: logoData.map((testimonial: any) => ({
      alt: testimonial.logo?.alt,
      url: testimonial.logo?.fluid,
    })),
  }

  const shape = {
    label: raw.shape_block_label,
    title: raw.shape_block_title.text,
    text: raw.shape_block_text.html,
    image: {
      alt: raw.shape_block_image.alt,
      url: raw.shape_block_image.fluid,
    },
    prices: raw.shape_block_prices.map((price: any, i: number) => ({
      price: price.price,
      label: price.label,
      caption: price.caption,
      button: {
        link: price.button_link,
        label: price.button_label,
        variant: i === 0 ? "primary" : undefined,
      },
    })),
  }

  const page = {
    title: raw.title.text,
    text: raw.intro.html,
    buttonLabel: raw.intro_button_label,
    image: {
      alt: raw.intro_image.alt,
      url: raw.intro_image.fluid,
      imageUrl: raw.intro_image.url,
    },
    features,
    logos,
    shape,
    ctaTitle: raw.cta_title.text,
    ctaText: raw.cta_text.html,
    ctaImage: {
      alt: raw.cta_image.alt,
      url: raw.cta_image.fluid,
    },
    teamRituals: ritualCarousel[0],
    ctaButtonLabel: raw.cta_button_label,
  }

  const content = {
    meta,
    page,
  }

  return <FeaturesPage content={content} />
}
